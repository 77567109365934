import { JsonProperty, Serializable } from 'typescript-json-serializer';
import SuperTask from '@/models/experiment/SuperTask';

@Serializable()
export default class GoogleFormsTask extends SuperTask {
  @JsonProperty('formsId')
  private _formsId: string;
  @JsonProperty('formsMatriculationNumberFieldId')
  private _formsMatriculationNumberFieldId: string;
  @JsonProperty('answerSpreadsheetId')
  private _answerSpreadsheetId: string;

  constructor(
    name: string,
    description: string,
    shortName: string,
    maxPoints: number,
    order: number,
    autoPoints: boolean,
    submissions: number[],
    experiment: number,
    formsId: string,
    formsMatriculationNumberFieldId: string,
    answerSpreadsheetId: string
  ) {
    super(name, description, shortName, maxPoints, order, autoPoints, submissions, experiment);
    this._formsId = formsId;
    this._formsMatriculationNumberFieldId = formsMatriculationNumberFieldId;
    this._answerSpreadsheetId = answerSpreadsheetId;
  }

  get formsId(): string {
    return this._formsId;
  }

  set formsId(value: string) {
    this._formsId = value;
  }

  get formsMatriculationNumberFieldId(): string {
    return this._formsMatriculationNumberFieldId;
  }

  set formsMatriculationNumberFieldId(value: string) {
    this._formsMatriculationNumberFieldId = value;
  }

  get answerSpreadsheetId(): string {
    return this._answerSpreadsheetId;
  }

  set answerSpreadsheetId(value: string) {
    this._answerSpreadsheetId = value;
  }
}
