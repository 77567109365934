import { JsonProperty, Serializable } from 'typescript-json-serializer';
import SuperTask from '@/models/experiment/SuperTask';

@Serializable()
export default class Task extends SuperTask {
  @JsonProperty('isPlaceHolder')
  private _isPlaceHolder: boolean;

  constructor(
    name: string,
    description: string,
    shortName: string,
    maxPoints: number,
    order: number,
    autoPoints: boolean,
    submissions: number[],
    experiment: number,
    isPlaceHolder: boolean
  ) {
    super(name, description, shortName, maxPoints, order, autoPoints, submissions, experiment);
    this._isPlaceHolder = isPlaceHolder;
  }

  get isPlaceHolder(): boolean {
    return this._isPlaceHolder;
  }

  set isPlaceHolder(value: boolean) {
    this._isPlaceHolder = value;
  }
}
