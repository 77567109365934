import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class SuperTask {
  @JsonProperty('id')
  private _id?: number | undefined;
  @JsonProperty('name')
  private _name: string;
  @JsonProperty('description')
  private _description: string;
  @JsonProperty('shortName')
  private _shortName: string;
  @JsonProperty('maxPoints')
  private _maxPoints: number;
  @JsonProperty('order')
  private _order: number;
  @JsonProperty('autoPoints')
  private _autoPoints: boolean;
  @JsonProperty('submissions')
  private _submissions: number[];
  @JsonProperty('experiment')
  private _experiment: number;

  constructor(
    name: string,
    description: string,
    shortName: string,
    maxPoints: number,
    order: number,
    autoPoints: boolean,
    submissions: number[],
    experiment: number
  ) {
    this._name = name;
    this._description = description;
    this._shortName = shortName;
    this._maxPoints = maxPoints;
    this._order = order;
    this._autoPoints = autoPoints;
    this._submissions = submissions;
    this._experiment = experiment;
  }

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get shortName(): string {
    return this._shortName;
  }

  set shortName(value: string) {
    this._shortName = value;
  }

  get maxPoints(): number {
    return this._maxPoints;
  }

  set maxPoints(value: number) {
    this._maxPoints = value;
  }

  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  get autoPoints(): boolean {
    return this._autoPoints;
  }

  set autoPoints(value: boolean) {
    this._autoPoints = value;
  }

  get submissions(): number[] {
    return this._submissions;
  }

  set submissions(value: number[]) {
    this._submissions = value;
  }

  get experiment(): number {
    return this._experiment;
  }

  set experiment(value: number) {
    this._experiment = value;
  }
}
