import { JsonProperty, Serializable } from 'typescript-json-serializer';
import SuperTask from '@/models/experiment/SuperTask';

@Serializable()
export default class ReviewTask extends SuperTask {
  @JsonProperty('reviewAssignment')
  private _reviewAssignment: number;

  constructor(
    name: string,
    description: string,
    shortName: string,
    maxPoints: number,
    order: number,
    autoPoints: boolean,
    submissions: number[],
    experiment: number,
    reviewAssignment: number
  ) {
    super(name, description, shortName, maxPoints, order, autoPoints, submissions, experiment);
    this._reviewAssignment = reviewAssignment;
  }

  get reviewAssignment(): number {
    return this._reviewAssignment;
  }

  set reviewAssignment(value: number) {
    this._reviewAssignment = value;
  }
}
