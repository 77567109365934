import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import Task from '@/models/experiment/Task';
import ReviewTask from '@/models/experiment/ReviewTask';
import GoogleFormsTask from '@/models/experiment/GoogleFormsTask';
import SuperTask from '@/models/experiment/SuperTask';
import { AxiosResponse } from 'axios';
import { TaskTypeEnum } from '@/models/experiment/TaskTypeEnum';
import { deserializeTaskType } from '@/serializer/EnumHelpers';

export default class TaskService {
  private static taskURL = '/task';
  private static reviewtaskURL = '/reviewtask';
  private static googleformstaskURL = '/googleformstask';

  public static saveNormalTask(task: Task): Promise<Task> {
    return MainService.post(this.taskURL, JSON.stringify(serialize(task))).then((response) => {
      return deserialize(response.data, Task);
    });
  }

  public static saveReviewTask(task: ReviewTask): Promise<ReviewTask> {
    return MainService.post(this.reviewtaskURL, JSON.stringify(serialize(task))).then((response) => {
      return deserialize(response.data, ReviewTask);
    });
  }

  public static saveGoogleFormsTask(task: GoogleFormsTask): Promise<GoogleFormsTask> {
    return MainService.post(this.googleformstaskURL, JSON.stringify(serialize(task))).then((response) => {
      return deserialize(response.data, GoogleFormsTask);
    });
  }

  public static getTaskType(taskId: number): Promise<TaskTypeEnum> {
    return MainService.get(this.taskURL + '/type/' + taskId).then((response) => {
      return deserializeTaskType(response.data);
    });
  }

  public static getNormalTaskById(taskId: number): Promise<Task> {
    return MainService.get(this.taskURL + '/' + taskId).then((response) => {
      return deserialize(response.data, Task);
    });
  }

  public static getReviewTaskById(taskId: number): Promise<ReviewTask> {
    return MainService.get(this.reviewtaskURL + '/' + taskId).then((response) => {
      return deserialize(response.data, ReviewTask);
    });
  }

  public static getGoogleFormsTaskById(taskId: number): Promise<GoogleFormsTask> {
    return MainService.get(this.googleformstaskURL + '/' + taskId).then((response) => {
      return deserialize(response.data, GoogleFormsTask);
    });
  }

  public static getAllNormalTasksForExperiment(experimentId: number): Promise<Task[]> {
    return MainService.get(this.taskURL + '/experiment/' + experimentId).then((response) => {
      return response.data.map((item) => deserialize(item, Task));
    });
  }

  public static getAllReviewTasksForExperiment(experimentId: number): Promise<ReviewTask[]> {
    return MainService.get(this.reviewtaskURL + '/experiment/' + experimentId).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewTask));
    });
  }

  public static getAllGoogleFormsTasksForExperiment(experimentId: number): Promise<GoogleFormsTask[]> {
    return MainService.get(this.googleformstaskURL + '/experiment/' + experimentId).then((response) => {
      return response.data.map((item) => deserialize(item, GoogleFormsTask));
    });
  }

  public static async getAllTasksForExperiment(experimentId: number): Promise<SuperTask[]> {
    const result: SuperTask[] = [];
    await this.getAllNormalTasksForExperiment(experimentId).then((res) => {
      res.map((i) => result.push(i));
    });
    await this.getAllReviewTasksForExperiment(experimentId).then((res) => {
      res.map((i) => result.push(i));
    });
    await this.getAllGoogleFormsTasksForExperiment(experimentId).then((res) => {
      res.map((i) => result.push(i));
    });
    return result.sort((a, b) => a.order - b.order);
  }

  public static deleteTask(taskid: number): Promise<AxiosResponse> {
    return MainService.delete(this.taskURL + '/' + taskid);
  }
}
